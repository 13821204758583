// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-articles-index-js": () => import("./../../../node_modules/@swizec/gatsby-theme-course-platform/src/pages/articles/index.js" /* webpackChunkName: "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-articles-index-js" */),
  "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-auth-0-callback-js": () => import("./../../../node_modules/@swizec/gatsby-theme-course-platform/src/pages/auth0_callback.js" /* webpackChunkName: "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-auth-0-callback-js" */),
  "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-blog-index-js": () => import("./../../../node_modules/@swizec/gatsby-theme-course-platform/src/pages/blog/index.js" /* webpackChunkName: "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-blog-index-js" */),
  "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-demo-mdx": () => import("./../../../node_modules/@swizec/gatsby-theme-course-platform/src/pages/demo.mdx" /* webpackChunkName: "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-demo-mdx" */),
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-appendix-more-databases-index-mdx": () => import("./../../../src/pages/appendix-more-databases/index.mdx" /* webpackChunkName: "component---src-pages-appendix-more-databases-index-mdx" */),
  "component---src-pages-claim-mdx": () => import("./../../../src/pages/claim.mdx" /* webpackChunkName: "component---src-pages-claim-mdx" */),
  "component---src-pages-databases-index-mdx": () => import("./../../../src/pages/databases/index.mdx" /* webpackChunkName: "component---src-pages-databases-index-mdx" */),
  "component---src-pages-dev-qa-prod-index-mdx": () => import("./../../../src/pages/dev-qa-prod/index.mdx" /* webpackChunkName: "component---src-pages-dev-qa-prod-index-mdx" */),
  "component---src-pages-downloads-index-mdx": () => import("./../../../src/pages/downloads/index.mdx" /* webpackChunkName: "component---src-pages-downloads-index-mdx" */),
  "component---src-pages-getting-started-index-mdx": () => import("./../../../src/pages/getting-started/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-index-mdx" */),
  "component---src-pages-glossary-mdx": () => import("./../../../src/pages/glossary.mdx" /* webpackChunkName: "component---src-pages-glossary-mdx" */),
  "component---src-pages-handling-secrets-index-mdx": () => import("./../../../src/pages/handling-secrets/index.mdx" /* webpackChunkName: "component---src-pages-handling-secrets-index-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-lambda-pipelines-index-mdx": () => import("./../../../src/pages/lambda-pipelines/index.mdx" /* webpackChunkName: "component---src-pages-lambda-pipelines-index-mdx" */),
  "component---src-pages-robust-backend-design-index-mdx": () => import("./../../../src/pages/robust-backend-design/index.mdx" /* webpackChunkName: "component---src-pages-robust-backend-design-index-mdx" */),
  "component---src-pages-serverless-architecture-principles-index-mdx": () => import("./../../../src/pages/serverless-architecture-principles/index.mdx" /* webpackChunkName: "component---src-pages-serverless-architecture-principles-index-mdx" */),
  "component---src-pages-serverless-authentication-index-mdx": () => import("./../../../src/pages/serverless-authentication/index.mdx" /* webpackChunkName: "component---src-pages-serverless-authentication-index-mdx" */),
  "component---src-pages-serverless-chrome-puppeteer-index-mdx": () => import("./../../../src/pages/serverless-chrome-puppeteer/index.mdx" /* webpackChunkName: "component---src-pages-serverless-chrome-puppeteer-index-mdx" */),
  "component---src-pages-serverless-dx-index-mdx": () => import("./../../../src/pages/serverless-dx/index.mdx" /* webpackChunkName: "component---src-pages-serverless-dx-index-mdx" */),
  "component---src-pages-serverless-elements-index-mdx": () => import("./../../../src/pages/serverless-elements/index.mdx" /* webpackChunkName: "component---src-pages-serverless-elements-index-mdx" */),
  "component---src-pages-serverless-flavors-index-mdx": () => import("./../../../src/pages/serverless-flavors/index.mdx" /* webpackChunkName: "component---src-pages-serverless-flavors-index-mdx" */),
  "component---src-pages-serverless-graphql-index-mdx": () => import("./../../../src/pages/serverless-graphql/index.mdx" /* webpackChunkName: "component---src-pages-serverless-graphql-index-mdx" */),
  "component---src-pages-serverless-monitoring-index-mdx": () => import("./../../../src/pages/serverless-monitoring/index.mdx" /* webpackChunkName: "component---src-pages-serverless-monitoring-index-mdx" */),
  "component---src-pages-serverless-performance-index-mdx": () => import("./../../../src/pages/serverless-performance/index.mdx" /* webpackChunkName: "component---src-pages-serverless-performance-index-mdx" */),
  "component---src-pages-serverless-pros-cons-index-mdx": () => import("./../../../src/pages/serverless-pros-cons/index.mdx" /* webpackChunkName: "component---src-pages-serverless-pros-cons-index-mdx" */),
  "component---src-pages-serverless-rest-api-index-mdx": () => import("./../../../src/pages/serverless-rest-api/index.mdx" /* webpackChunkName: "component---src-pages-serverless-rest-api-index-mdx" */),
  "component---src-pages-thanks-mdx": () => import("./../../../src/pages/thanks.mdx" /* webpackChunkName: "component---src-pages-thanks-mdx" */)
}

