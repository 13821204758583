module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/vercel/path0/node_modules/gatsby-remark-images","id":"28c42f28-53d0-5cae-aad5-aee248175898","name":"gatsby-remark-images","version":"4.2.0","pluginOptions":{"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx",".md"],"remarkPlugins":[null],"gatsbyRemarkPlugins":["gatsby-remark-copy-linked-files",{"resolve":"gatsby-remark-copy-relative-linked-files","options":{"ignoreFileExtensions":[".md",".mdx",".gif",".png",".jpg",".jpeg"]}},{"resolve":"gatsby-remark-giphy","options":{"giphyApiKey":"tvyI1ARG6FOkW9PUzmgubJ3iY5P5rJmO","useVideo":true,"embedWidth":"80%"}},{"resolve":"gatsby-remark-images","options":{"markdownCaptions":false,"maxWidth":890,"linkImagestoOriginal":false,"showCaptions":["title","alt"],"withWebp":true,"wrapperStyle":"text-align: center; font-style: italic","tracedSVG":{"color":"lightgray","optTolerance":0.4,"turdSize":100,"turnPolicy":"TURNPOLICY_MAJORITY"}}},{"resolve":"/vercel/path0/node_modules/@swizec/gatsby-theme-course-platform/src/gatsby-remark-social-cards"},{"resolve":"gatsby-remark-embedder","options":{"customTransformers":[{"name":"YouTube"},{"name":"Twitter"}],"services":{"Instagram":{},"Twitter":{"consumer_key":"LloloCAuhbQFV17DWkrwdvFgz","consumer_secret":"1puU3lcwQBiqpJjhkjoBG5krJohR6tprN0tj9G6uCacMWnZ6k0"}}}}],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/vercel/path0"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-simple-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Course platform","short_name":"Course platform","description":"Theme for a course platform","start_url":"/","background_color":"#fff","theme_color":"#FF002B","display":"standalone","icon":"/vercel/path0/node_modules/@swizec/gatsby-theme-course-platform/static/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e95fb9485e81929697ed35b4e95cb178"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-1464315-32","head":false,"anonymize":false,"respectDNT":true,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"2634718133254322"},
    },{
      plugin: require('../node_modules/@swizec/gatsby-theme-course-platform/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Serverless Handbook for Frontend Engineers","short_name":"Serverless Handbook for Frontend Engineers","description":"Learn everything you need to dive into modern backend. Understand any backend.","start_url":"/","background_color":"#fff","theme_color":"#FF002B","display":"standalone","icon":"./static/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d916f7a3e9f6c87a67004503dd556883"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
