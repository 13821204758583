import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/getting-started"
        }}>{`Getting Started`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-pros-cons"
        }}>{`Serverless Pros & Cons`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-flavors"
        }}>{`AWS, Azure, Vercel, Netlify, or Firebase?`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-dx"
        }}>{`Good serverless DX`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-architecture-principles"
        }}>{`Architecture principles`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-elements"
        }}>{`Lambdas, queues, etc`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/robust-backend-design"
        }}>{`Robust backend design`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/databases"
        }}>{`Where to store data`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-rest-api"
        }}>{`Creating a REST API`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-graphql"
        }}>{`Using GraphQL`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/lambda-pipelines"
        }}>{`Lambda pipelines`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-monitoring"
        }}>{`Monitoring serverless apps`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/dev-qa-prod"
        }}>{`Dev, QA, and prod`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-performance"
        }}>{`Serverless performance`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-chrome-puppeteer"
        }}>{`Serverless Chrome puppeteer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/handling-secrets"
        }}>{`Handling secrets`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serverless-authentication"
        }}>{`Dealing with authentication`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/glossary"
        }}>{`Glossary`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/appendix-more-databases"
        }}>{`Appendix: More databases`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/downloads"
        }}>{`Download PDF/epub/mobi`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      